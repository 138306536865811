import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'empty' },
      component: () => import('./views/Login.vue')
    },
    {
      path: '/gen',
      name: 'old',
      meta: { layout: 'old' }
    },
    {
      path: '/gen2',
      name: 'new',
      meta: { layout: 'new' }
    },
    {
      path: '/crypto',
      name: 'crypto',
      meta: { layout: 'crypto' }
    },
    {
      path: '/demo',
      name: 'demo',
      meta: { layout: 'demo' }
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   meta: { layout: 'empty' },
    //   component: () => import('./views/Register.vue')
    // },
    {
      path: '/modules',
      name: 'modules',
      meta: { layout: 'main' },
      component: () => import('./views/Modules.vue')
    },
    {
      path: '/palettes',
      name: 'palettes',
      meta: { layout: 'main' },
      component: () => import('./views/Palettes.vue')
    },
    {
      path: '/privpalettes',
      name: 'privatepalettes',
      meta: { layout: 'main' },
      component: () => import('./views/PrivatePalettes.vue')
    },
    {
      path: '/api.js'
      // component: () => import('./views/PrivatePalettes.vue')
    },
    // {
    //   path: '/detail/:id',
    //   name: 'detail',
    //   meta: { layout: 'main' },
    //   component: () => import('./views/Detail.vue')
    // },
    {
      path: '/',
      name: 'history',
      meta: { layout: 'main' },
      component: () => import('./views/History.vue')
    },
    {
      path: '/preset',
      name: 'preset',
      meta: { layout: 'main' },
      component: () => import('./views/Preset.vue')
    },
    {
      path: '/module',
      name: 'module',
      meta: { layout: 'main' },
      component: () => import('./views/SingleModule.vue')
    }
    // {
    //   path: '/profile',
    //   name: 'profile',
    //   meta: { layout: 'main' },
    //   component: () => import('./views/Profile.vue')
    // },
    // {
    //   path: '/record',
    //   name: 'record',
    //   meta: { layout: 'main' },
    //   component: () => import('./views/Record.vue')
    // }
  ]
})
